import Vue from 'vue'

// axios
import axios from 'axios'

const token = '1|eB5uaN03XC7lTEJtOEoCICGBuDmu7j2R74aRwuh2'
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://systemadmin.sudocode.net/api',
  // baseURL: 'http://127.0.0.1:8000/api',
  timeout: 100000,
  headers: { 'X-Custom-Header': 'foobar' },
})
axiosIns.interceptors.request.use(config => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${token}`
  return config
})

Vue.prototype.$http = axiosIns

export default axiosIns
